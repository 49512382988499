/* eslint-disable no-underscore-dangle */
import React, { FC, useEffect, useState } from 'react';
import { PageProps } from 'gatsby';
import queryString from 'query-string';

import { Header } from '@/newcar/ui/contract/components';
import Layout from '@/common/ui/template/Layout';
import SEO from '@/common/ui/organism/SEO';
import { Helmet } from 'react-helmet';
import Form from '@/newcar/ui/contract/organism/Form';
import { simulationManagerStore } from '@/newcar/core/store/simulationManagerStore';

import {
  ContractType,
} from '@/newcar/util/const/contract';

import {
  META_TITLES,
  META_DESCRIPTION,
} from '@/newcar/ui/contract/config';
import { setAllList } from '@/newcar/util/setAllList';

export interface ContractProps {
  type: ContractType;
}

// 申請フォーム ご連絡先
const Page: FC<PageProps<{}, ContractProps>> = ({ pageContext }) => {
  const {
    type,
  } = pageContext;

  if (type === 'contact_info') {
    // 商品データを再設定する
    setAllList();
  }

  const [isHousingTest, setHousingTest] = useState<boolean>();
  const [isNonActiveCtaTest, setNonActiveCtaTest] = useState<boolean>();

  useEffect(() => {
    // クエリがある場合は車両情報作成
    if (window && type === 'contact_info' && simulationManagerStore) {
      const query = queryString.parse(window.location.search);
      if (query.carid || query.carId) {
        simulationManagerStore.createCampaignBreakdownFromQuery(query);
      }
    }
  }, []);

  // ABテスト実施用クリックイベント、VWOから発火する
  const handleHousingClick = () => {
    setHousingTest(true);
  };

  const handleNonActiveCtaClick = () => {
    setNonActiveCtaTest(true);
  };

  return (
    <Layout>
      <SEO title={META_TITLES[type]} description={META_DESCRIPTION[type]} />
      <Helmet>
        <meta name="robots" content="noindex" />
      </Helmet>
      <Header />
      <Form
        type={type}
        isHousingTest={isHousingTest}
        isNonActiveCtaTest={isNonActiveCtaTest}
      />
      <button id="housing_abtest" type="button" hidden onClick={handleHousingClick}>
        radioPosition
      </button>
      <button id="nonactive_cta_abtest" type="button" hidden onClick={handleNonActiveCtaClick}>
        nonActiveCta
      </button>
    </Layout>
  );
};
export default Page;
